import { useMemo, useState } from "react"
import styled from "styled-components"

import { subMonths } from "date-fns"

import { EmptyState } from "src/components/EmptyState"
import { ParadiseBarChart } from "src/components/Paradise/ParadiseCharts/ParadiseBarChart"
import { ParadiseLineChart } from "src/components/Paradise/ParadiseCharts/ParadiseLineChart"
import { useParadiseDeviceReadings } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceReadings/useParadiseDeviceReadings"
import { ParadiseLayout } from "src/components/Paradise/sharedStyles"
import {
  deviceReadingType,
  p1DeviceReadingType,
  p2DeviceReadingType,
} from "src/data/devices/logic/deviceConstants"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import {
  DeviceReadingType,
  HardwareType,
} from "src/data/devices/types/deviceTypes"
import { useFetchParadiseDevice } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { Breadcrumbs } from "src/ui/Breadcrumbs/Breadcrumbs"
import {
  ComboboxStateful,
  TComboboxOption,
} from "src/ui/Combobox/ComboboxStateful"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { spacing } from "src/ui/spacing"

export function ParadiseDeviceReadings({
  deviceId,
  type,
}: {
  deviceId: string
  type: DeviceReadingType
}) {
  const [selectedDate, setSelectedDate] = useState({
    startAt: subMonths(new Date(), 1),
    endAt: new Date(),
  })

  const { navigate } = useRouter()

  const fetchParadiseDevice = useFetchParadiseDevice({
    deviceId,
  })
  const device = fetchParadiseDevice.data

  const deviceReadings = useParadiseDeviceReadings({
    deviceId,
    type,
    dateRange: selectedDate,
  })

  const availableReadingOptions = useMemo(() => {
    if (device) {
      if (
        !device.hardware_version ||
        getDeviceHardwareType(device.hardware_version).type === HardwareType.P1
      ) {
        return [
          ...convertToComboboxOptions(p1DeviceReadingType),
          ...convertToComboboxOptions(deviceReadingType),
        ]
      } else {
        return [
          ...convertToComboboxOptions(p2DeviceReadingType),
          ...convertToComboboxOptions(deviceReadingType),
        ]
      }
    } else {
      return []
    }
  }, [device])

  const graphContent = useMemo(() => {
    if (deviceReadings.isLoading) {
      return (
        <LoadingWrapper>
          <MCircularProgress />
        </LoadingWrapper>
      )
    }

    if (
      type === "motion_events" &&
      hasReadings(deviceReadings.motionReadings?.values)
    ) {
      return (
        <ParadiseBarChart
          data={deviceReadings.motionReadings?.values}
          tooltip={{ unit: "" }}
          canExport
          zoom="xy"
        />
      )
    }

    if (hasReadings(deviceReadings.dashboardReadings)) {
      return (
        <ParadiseLineChart
          data={deviceReadings.dashboardReadings}
          tooltip={{ unit: "" }}
          canExport
          smooth={false}
          zoom="xy"
        />
      )
    }

    if (hasReadings(deviceReadings.readings?.values)) {
      return (
        <ParadiseLineChart
          data={deviceReadings.readings.values}
          tooltip={{ unit: "" }}
          canExport
          smooth={false}
          zoom="xy"
        />
      )
    }

    return <EmptyState icon={<></>} title="No readings available" />
  }, [deviceReadings, type])

  return (
    <ParadiseLayout>
      <Titlebar
        title="Device reading data"
        breadcrumbs={
          <Breadcrumbs
            items={[
              {
                label: "Devices",
                to: Routes.ParadiseDevices.location(),
              },
              {
                label: deviceId,
                to: Routes.ParadiseDevice.location(deviceId),
              },
              {
                label: type,
                to: Routes.ParadiseDeviceReadings.location({ deviceId, type }),
              },
            ]}
          />
        }
      />

      <SelectWrapper>
        <ComboboxStateful
          label="Reading type"
          selectedValue={type}
          options={availableReadingOptions}
          popoverMaxHeight={"500px"}
          onChange={(v) => {
            if (v) {
              navigate(
                Routes.ParadiseDeviceReadings.location({
                  deviceId,
                  type: v as DeviceReadingType,
                })
              )
            }
          }}
          onSearch={() => {}}
        />
        <div>
          <DateRangeBox>
            <DateRangePicker
              key={JSON.stringify(selectedDate)}
              startDate={selectedDate.startAt}
              endDate={selectedDate.endAt}
              onDateRangeChange={({ startDate, endDate }) => {
                setSelectedDate({
                  startAt: startDate,
                  endAt: endDate,
                })
              }}
              dateRangeProps={{
                minBookingDays: 2,
              }}
              defaultPresetKey="DAY"
            />
          </DateRangeBox>
        </div>

        {graphContent}
      </SelectWrapper>
    </ParadiseLayout>
  )
}

const SelectWrapper = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DateRangeBox = styled.div`
  display: flex;
`

function convertToComboboxOptions(
  arr: readonly DeviceReadingType[]
): TComboboxOption[] {
  return arr.map((s) => ({ value: s, label: s }))
}

function hasReadings<Type extends unknown[]>(
  readings: Type | undefined
): readings is Type {
  if (readings && readings.length > 0) {
    return true
  }

  return false
}
