import { useMemo } from "react"

import { fromUnixTime } from "date-fns"

import { PointMetrics } from "src/data/devices/logic/deviceConstants"
import {
  useFetchDeviceMotionReadings,
  useFetchDeviceReadings,
} from "src/data/devices/queries/deviceQueries"
import { DeviceReadingType } from "src/data/devices/types/deviceTypes"
import { useFetchParadiseDeviceMetrics } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"

export function useParadiseDeviceReadings({
  deviceId,
  type,
  dateRange,
}: {
  deviceId: string
  type: DeviceReadingType
  dateRange: {
    startAt: Date
    endAt: Date
  }
}) {
  // This is only set if the reading should be fetched from /dashboard or not
  const isDashboardReading = Object.keys(PointMetrics).includes(
    type.toUpperCase()
  )
  // This is only set if reading should be fetched from public minut api
  const isDeviceReading = type !== "motion_events" && !isDashboardReading
  // This is only set if reading is motion from public minut api
  const isMotionReadings = type === "motion_events" && !isDashboardReading

  const fetchDeviceReadings = useFetchDeviceReadings({
    orgId: "paradise",
    deviceId,
    type,
    startAt: dateRange.startAt.toISOString(),
    endAt: dateRange.endAt.toISOString(),
    timeResolution: 1,
    includeMinMax: false,
    nullFill: false,
    options: {
      refetchOnWindowFocus: false,
      enabled: isDeviceReading,
    },
  })

  const fetchMotionReadings = useFetchDeviceMotionReadings({
    orgId: "paradise",
    deviceId,
    startAt: dateRange.startAt.toISOString(),
    endAt: dateRange.endAt.toISOString(),
    timeResolution: 3600,
    includeMinMax: false,
    nullFill: false,
    options: {
      refetchOnWindowFocus: false,
      enabled: isMotionReadings,
    },
  })

  const fetchDashboardDeviceMetrics = useFetchParadiseDeviceMetrics({
    deviceId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    metricId: PointMetrics[type.toUpperCase() as any] as any,
    filter: {
      start_at: dateRange.startAt.toISOString(),
      end_at: dateRange.endAt.toISOString(),
      limit: 100000,
    },
    options: {
      refetchOnWindowFocus: false,
      enabled: isDashboardReading,
    },
  })

  const isLoading =
    (fetchDeviceReadings.isFetching && fetchDeviceReadings.isLoading) ||
    (fetchMotionReadings.isFetching && fetchMotionReadings.isLoading) ||
    (fetchDashboardDeviceMetrics.isFetching &&
      fetchDashboardDeviceMetrics.isLoading)

  const hasMotionReadings =
    fetchMotionReadings.isSuccess && fetchMotionReadings.data.values.length > 0

  const hasDashboardReadings =
    fetchDashboardDeviceMetrics.isSuccess &&
    fetchDashboardDeviceMetrics.data.metrics.length > 0

  const dashboardReadings = useMemo(() => {
    const sorted = fetchDashboardDeviceMetrics.data?.metrics.sort(
      (a, b) => a.timestamp_ms - b.timestamp_ms
    )

    return sorted?.map((metric) => ({
      datetime: fromUnixTime(metric.timestamp_ms).toISOString(),
      value: metric.metric_value,
    }))
  }, [fetchDashboardDeviceMetrics.data])

  return {
    readings: fetchDeviceReadings.data,
    dashboardReadings: dashboardReadings,
    motionReadings: fetchMotionReadings.data,
    hasMotionReadings,
    hasDashboardReadings,
    isLoading,
  }
}
